body {
  /* background-color: #e5e203; */
  background-color: white;
  color: #666;
}

.navbar-light .navbar-brand, .navbar-light .navbar-nav .nav-link {
  color: #e73291 !important;
  font-weight: bold;
}

.navbar-light .navbar-toggler {
  border: 2px solid #e73291;
  color: #e73291;
}

.pink {
  color: #e73291;
}
